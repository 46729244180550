<template>
  <div class="doctor-list-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item><i class="icon-home"></i>医生</Breadcrumb-item>
        <Breadcrumb-item>促进记录</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="filter-list-tpl">
        <Table stripe :columns="columns" :data="boostRecordList" @on-selection-change="selectOne"></Table>
        <Row>
          <Col span="12">
          <div class="table-oprate">
            <Button @click="delRecord" v-if="this.power.indexOf('医生--删除促进记录的权限') > -1">删除</Button>
          </div>
          </Col>
          <Col span="12">
          <span class="records">共{{pageTotal}}条记录</span>
          </Col>
        </Row>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page :total="pageTotal" :current="param.page" :page-size="param.size" @on-change="changePage"></Page>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import doctorService from '@/services/doctorService';
export default {
	components: {
		'v-title': vTitle,
	},
	data() {
		return {
			param: {
				page: 1,
				size: 10,
				member_id: '',
			},
			del: {
				boost_ids: '',
			},
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '记录日期', key: 'create_time', align: 'center' },
				{ title: '上次促进日期', key: 'last_time', align: 'center' },
				{ title: '下次促进日期', key: 'next_time', align: 'center' },
				{ title: '会员姓名', key: 'member_name', align: 'center' },
				{ title: '所属分院', key: 'branch_name', align: 'center' },
				{ title: '联系电话', key: 'mobile', align: 'center' },
				{ title: '方案名称', key: 'plan_name', align: 'center' },
				{ title: '医生', key: 'doctor_name', align: 'center' },
				{
					title: '药物情况',
					key: '',
					width: 120,
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									attrs: {
										target: '_blank',
										href:
											'/#/base/member/detail/' +
											this.boostRecordList[params.index].member_id +
											'/4',
									},
									style: {
										margin: '0 10px',
										color: '#ff8400',
									},
								},
								'查看',
							),
						]);
					},
				},
				{
					title: '促进结果',
					key: 'boost_result',
					width: 120,
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									style: {
										margin: '0 10px',
										color: '#ff8400',
									},
									on: {
										click: () => {
											this.goDetail(params.index);
										},
									},
								},
								'已提交',
							),
						]);
					},
				},
			],
			boostRecordList: [],
			pageTotal: 0,
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	created() {
		this.param.member_id = this.memberId;
		this.getRecordList();
		this.power = localStorage.getItem('userPower');
	},
	methods: {
		getRecordList() {
			doctorService.getRecordList(this.param).then((data) => {
				this.boostRecordList = data.list;
				this.pageTotal = data.row_size;
				this.del.boost_ids = '';
			});
		},
		selectOne(selection) {
			let arrBoostId = [];
			for (var i = 0; i < selection.length; i++) {
				arrBoostId.push(selection[i].boost_id);
			}
			this.del.boost_ids = arrBoostId.join(',');
		},
		delRecord() {
			if (!this.del.boost_ids) {
				this.$Message.warning('请先选择要删除的促进记录');
			} else {
				this.$Modal.confirm({
					title: '确认',
					content: '确定删除所选促进记录吗',
					onOk: () => {
						this.okdel();
					},
				});
			}
		},
		okdel() {
			doctorService.deleteBoost(this.del).then(() => {
				this.getRecordList();
			});
		},
		changePage(page) {
			this.param.page = page;
			this.getRecordList();
		},
		goDetail(index) {
			this.$router.push({
				name: 'doctorDetail',
				params: { boost_id: this.boostRecordList[index].boost_id },
			});
		},
	},
};
</script>

<style lang="css" scoped>
</style>
